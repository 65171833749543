import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import truncate from "utils/truncate.utils";

type Description = string;
type HasLimit = boolean;
type IsReadMore = boolean;
type HelperText = string;
type OnClick = () => void;

function useReadMore(text: string, limit: number = 170): [Description, HasLimit, HelperText, OnClick, IsReadMore] {
	const { t } = useTranslation("common");
	const [isReadMore, setIsReadMore] = useState(false);
	const handleBreakLines = text.replace(/\n/g, "<br>");

	const hasLimit = text.length > limit;
	const truncatedText = truncate(handleBreakLines, { length: limit, separator: " ", ellipsis: "", keepHtml: true });
	const description = isReadMore ? handleBreakLines : truncatedText;

	const helperText = isReadMore ? t("global.showless") : t("global.showmore");

	const onClickButton = () => setIsReadMore((prevState) => !prevState);

	return [description, hasLimit, helperText, onClickButton, isReadMore];
}

export default useReadMore;
