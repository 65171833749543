import { Disclosure, Transition } from "@headlessui/react";
import useTranslation from "next-translate/useTranslation";
import { Fragment } from "react";
import { MdArrowDropDown } from "react-icons/md";

type Props = {
	title: string;
	totalItems: number;
	children: React.ReactNode;
	defaultOpen?: boolean;
};
export default function Accordion({ title, totalItems, defaultOpen = false, children }: Props) {
	const { t } = useTranslation("common");
	const services = t("sections.services.accordion_label", { count: totalItems });

	return (
		<Disclosure defaultOpen={defaultOpen}>
			{({ open }) => (
				<>
					<Disclosure.Button className="flex w-full items-center rounded-lg bg-primary-50 transition-colors space-x-2 md:space-x-3 py-4 px-5 pl-4 focus:outline-none focus-visible:ring focus-visible:ring-primary-300 focus-visible:ring-opacity-75 mb-4">
						<MdArrowDropDown
							className={`${
								open ? "rotate-180" : ""
							} h-6 w-6 md:h-8 md:w-8 transform transition-transform flex-none`}
						/>
						<span className="font-medium truncate text-sm md:text-base">{title}</span>
						<span className="opacity-75 text-sm whitespace-nowrap">({services})</span>
					</Disclosure.Button>
					<Transition
						as={Fragment}
						enter="transition duration-500 ease-out"
						enterFrom="transform opacity-0"
						enterTo="transform opacity-100"
						leave="transition duration-200 ease-out"
						leaveFrom="transform opacity-100"
						leaveTo="transform opacity-0"
					>
						<Disclosure.Panel className="pb-4">{children}</Disclosure.Panel>
					</Transition>
				</>
			)}
		</Disclosure>
	);
}
