import React, { useMemo, memo } from "react";
import Image from "next/image";
import BookNow from "components/BookNow/BookNow";
import Card from "components/Cards/Card";
import VirtualBadge from "components/Badges/Virtual.badge";
import useReadMore from "hooks/useReadmore";
import { Image as ImageType, ServiceUnavailable } from "types/business.types";

import { useBusinessProfile } from "hooks/useFetch";
import { resolvedPublicPath } from "utils/utils";
import SafelySetInnerHTML from "components/Render/HTML.render";

type ReduceReturnType = {
	[K in (typeof ServiceUnavailable)[number]]?: boolean;
};

type Props = {
	id: number;
	title: string;
	description: string;
	duration: number;
	imageUrl: ImageType;
	hasVirtualBadge?: boolean;
	price: number;
	unavailableItems: typeof ServiceUnavailable | undefined;
};

const ServiceCard = ({
	id,
	title,
	description,
	hasVirtualBadge,
	imageUrl,
	duration,
	price,
	unavailableItems,
}: Props) => {
	const unavailableItem = useMemo(
		() => unavailableItems?.reduce<ReduceReturnType>((obj, cur) => ({ ...obj, [cur]: true }), {}),
		[unavailableItems]
	);

	const [truncated, hasLimit] = useReadMore(description || "", 320);
	const { data: businessProfile } = useBusinessProfile();
	const widgetSetting = businessProfile?.data;

	const priceFormatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: widgetSetting?.currency.name,
	});

	const showPrice = (price === 0 && "Free") || priceFormatter.format(price);

	const hasDurationOrPrice = !unavailableItem?.duration || !unavailableItem?.price;
	const hasDurationAndPrice = !unavailableItem?.duration && !unavailableItem?.price;

	const isImageHidden = unavailableItem?.image;

	return (
		<Card>
			<div className="flex gap-4 sm:gap-8 items-start flex-col sm:flex-row">
				<div className="flex-auto">
					<h4 className="font-medium text-lg mb-1">{title}</h4>
					{(hasVirtualBadge || hasDurationOrPrice) && (
						<div className="inline-flex items-center space-x-2">
							{hasVirtualBadge && <VirtualBadge />}

							{hasDurationOrPrice && (
								<span className="bg-primary-50 px-2 py-1 rounded-md text-xs">
									{!unavailableItem?.duration && `${duration / 60} min`}
									{hasDurationAndPrice && " | "}
									{!unavailableItem?.price && `${showPrice}`}
								</span>
							)}
						</div>
					)}

					{!unavailableItem?.description && (
						<p className="text-sm mt-2 text-[#46474a] leading-6 break-word">
							<SafelySetInnerHTML htmlText={truncated} />

							{hasLimit && " ..."}
						</p>
					)}
				</div>
				<div className="w-full sm:w-auto min-w-[9rem] max-w-none sm:max-w-[9rem] flex-none flex flex-col space-y-3 justify-end sm:pl-4">
					{!isImageHidden && (
						<div className="relative aspect-[1.6] sm:aspect-[1.18] overflow-hidden">
							{(imageUrl?.url && (
								<Image
									className="w-full h-full rounded-lg bg-gray-200 object-cover select-none"
									src={imageUrl?.url || resolvedPublicPath("service-placeholder.svg")}
									alt={imageUrl?.alt || title}
									width={300}
									height={254}
									quality={90}
									loading="lazy"
								/>
							)) || (
								<div className="absolute inset-0 rounded-lg flex items-center justify-center bg-gray-200 text-gray-500">
									<svg
										className="w-6 h-6"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 32 32"
									>
										<path
											fill="currentColor"
											d="m3.002 26.763 1.853.796V14.728l-3.36 8.327a2.897 2.897 0 0 0 1.507 3.708Zm26.966-5.258L23.113 4.497a2.785 2.785 0 0 0-2.507-1.748 2.72 2.72 0 0 0-1.093.213L9.327 7.296a2.84 2.84 0 0 0-1.701 2.558c-.014.39.057.777.207 1.137l6.86 17.007a2.768 2.768 0 0 0 2.53 1.748c.365 0 .727-.073 1.065-.213L28.466 25.2a2.861 2.861 0 0 0 1.507-3.694h-.005Zm-12.723 5.4L10.387 9.91l10.164-4.318h.015l6.846 16.98-10.167 4.332Z"
										/>
										<path
											fill="currentColor"
											d="M14.635 12.907a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM8.168 27.746a2.005 2.005 0 0 0 2 2h1.455l-3.455-8.34v6.34Z"
										/>
									</svg>
								</div>
							)}
						</div>
					)}

					<BookNow
						uniqueIdentifier={widgetSetting?.widget.uniqueIdentifier}
						type="service"
						id={id}
						buttonWide
					/>
				</div>
			</div>
		</Card>
	);
};

export default memo(ServiceCard);
