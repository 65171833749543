import DOMPurify from "isomorphic-dompurify";

type Props = {
	htmlText: string;
};

const SafelySetInnerHTML = ({ htmlText }: Props) => {
	const cleanedHTML = DOMPurify.sanitize(htmlText);
	return <span dangerouslySetInnerHTML={{ __html: cleanedHTML }} />;
};

export default SafelySetInnerHTML;
