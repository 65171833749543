type TruncateOptions = {
	length: number;
	separator?: string;
	ellipsis?: string;
	keepHtml?: boolean;
};

function truncate(text: string, options: TruncateOptions): string {
	const { length, separator = " ", ellipsis = "...", keepHtml = false } = options;
	let truncatedText = text;

	// Handle HTML tags
	let htmlTags: string[] = [];
	if (!keepHtml) {
		truncatedText = text.replace(/<[^>]*>/g, (match) => {
			htmlTags.push(match);
			return "";
		});
	}

	// Truncate by word boundary
	if (truncatedText.length > length) {
		const words = truncatedText.split(separator);
		let truncatedWords = words.slice(0, Math.max(1, words.length - 1));
		while (truncatedWords.join(separator).length + ellipsis.length > length) {
			truncatedWords.pop();
		}
		truncatedText = truncatedWords.join(separator) + ellipsis;
	}

	// Add back HTML tags
	if (!keepHtml && htmlTags.length > 0) {
		truncatedText = htmlTags.reverse().reduce((text, tag) => tag + text, truncatedText);
	}

	return truncatedText;
}

export default truncate;
