import React, { memo } from "react";
import { MdOutlineVoiceChat } from "react-icons/md";
import useTranslation from "next-translate/useTranslation";

function VirtualBadge() {
	const { t } = useTranslation("common");
	return (
		<span className="inline-flex items-center text-primary-500 text-sm">
			<MdOutlineVoiceChat className="h-4 w-4 mr-1" />
			{t("sections.services.virtual")}
		</span>
	);
}

export default memo(VirtualBadge);
