import { memo, useMemo } from "react";
import groupBy from "lodash-es/groupBy";
import orderBy from "lodash-es/orderBy";
import { useBusinessProfile } from "hooks/useFetch";
import useTranslation from "next-translate/useTranslation";
import { AppointmentType, ServiceFieldsType } from "types/business.types";
import { PaginateGenericType } from "types/generic.types";
import Accordion from "components/Accordion/Accordion";
import ServiceCard from "components/Services/Service.card";

type Props = { services: PaginateGenericType<ServiceFieldsType> };

function ServicesCategorized({ services }: Props) {
	const { t } = useTranslation("common");
	const { data: businessProfile } = useBusinessProfile();
	const widgetSetting = businessProfile?.data.widget;
	const unavailableItems = widgetSetting?.settings.service.unavailableItems;
	const categorized = useMemo(() => {
		const othersLabel = t("sections.services.others");
		const grouped = groupBy(services.items, (v) => v.category?.name || othersLabel);
		const keys = Object.keys(grouped);

		const preferredOrder = [othersLabel];
		const sortedKeys = orderBy(keys, [(name) => name.toLowerCase()], ["asc"]);
		const sorted = sortedKeys
			.sort((a, b) => preferredOrder.indexOf(a) - preferredOrder.indexOf(b))
			.reduce<{ key: string; cards: ServiceFieldsType[] }[]>((accumulator: any, key: string) => {
				accumulator.push({ key, cards: grouped[key] });
				return accumulator;
			}, []);

		return sorted;
	}, [services.items, t]);

	return (
		<>
			{categorized.map(({ key, cards }, i) => (
				<Accordion key={key} defaultOpen={i === 0} title={key} totalItems={cards.length}>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
						{cards?.map((service) => (
							<ServiceCard
								id={service.id}
								key={service.id}
								hasVirtualBadge={service.appointmentType === AppointmentType.Virtual}
								title={service.name}
								description={service.description}
								imageUrl={service.image}
								duration={service.duration}
								price={service.price}
								unavailableItems={unavailableItems}
							/>
						))}
					</div>
				</Accordion>
			))}
		</>
	);
}

export default memo(ServicesCategorized);
